import { LocalizationProvider } from "@mui/x-date-pickers"
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3"
import { DATE_FORMAT } from "constant"
import PropTypes from "prop-types"
import React from "react"
import { Controller } from "react-hook-form"
import { getNearestSunday } from "util/date"

const DatePicker = React.forwardRef(
	(
		{
			control,
			name,
			label,
			disableFuture = true,
			disablePast = false,
			showSundayOnly = true,
			...rest
		},
		ref
	) => {
		return (
			<Controller
				name={name}
				control={control}
				render={({ field }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<MuiDatePicker
							label={label}
							value={field.value}
							defaultValue={() => getNearestSunday()}
							onChange={(date) => field.onChange(date)}
							shouldDisableDate={showSundayOnly ? (day) => day.getDay() !== 0 : undefined}
							format={DATE_FORMAT}
							slotProps={{
								textField: {
									onKeyDown: (e) => {
										e.preventDefault()
									},
								},
							}}
							disableFuture={disableFuture}
							disablePast={disablePast}
						/>
					</LocalizationProvider>
				)}
				{...rest}
			/>
		)
	}
)

export default DatePicker

DatePicker.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	control: PropTypes.object.isRequired,
	disableFuture: PropTypes.bool,
	disablePast: PropTypes.bool,
	showSundayOnly: PropTypes.bool,
}

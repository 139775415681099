export const ROUTE_ATEENDANCE = "/attendance"
export const ROUTE_PRAYS = "/prays"
export const ROUTE_LOGIN = "/login"
export const ROUTE_PROGRAMS = "/programs"
export const ROUTE_SERMON = "/sermon"
export const ROUTE_SERMON_WRITE = "/sermon/write"
export const ROUTE_SERMON_MODIFY = "/sermon/:id/modify"
export const ROUTE_SERMON_DETAIL = "/sermon/:id"
export const ROUTE_NEWS = "/news"
export const ROUTE_NEWS_WRITE = "/news/write"
export const ROUTE_NEWS_MODIFY = "/news/:id/modify"
export const ROUTE_NEWS_DETAIL = "/news/:id"

import { yupResolver } from "@hookform/resolvers/yup"
import {
	Card,
	FormControlLabel,
	FormLabel,
	Grid,
	MenuItem,
	Select,
	Switch,
	TextField,
	Typography,
} from "@mui/material"
import DatePicker from "components/DatePicker"
import ErrorMessage from "components/ErrorMessage"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import PropTypes from "prop-types"
import { useLayoutEffect } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import image from "../../assets/images/upload_button.png"
import { ImageWithControls, ScrollableImageList } from "../../layouts/news/components"

export default function Form({
	formFields,
	initialValues = {},
	validationSchema,
	onSubmit,
	submitButtonDisabled,
}) {
	const navigate = useNavigate()

	const methods = useForm({
		defaultValues: initialValues,
		resolver: validationSchema && yupResolver(validationSchema),
		mode: "onBlur",
	})

	const { handleSubmit } = methods

	useLayoutEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<FormProvider {...methods}>
			<form noValidate onSubmit={handleSubmit(onSubmit)}>
				{formFields?.map(({ label, fields }, index) => (
					<Card key={`form-${label}-${index}`} sx={{ p: 4 }}>
						{label && <Typography variant="h4">{label}</Typography>}

						<Grid container spacing={2} pt={2}>
							{fields.map((field, idx) => {
								const { name, title, type, ...rest } = field

								return (
									<Field
										key={`${index}-${name}-${idx}`}
										name={name}
										title={title}
										type={type}
										{...rest}
									/>
								)
							})}
						</Grid>
					</Card>
				))}
				<MDBox display="flex" justifyContent="center" alignItems="center" gap={2}>
					<MDButton
						sx={{ mt: 2 }}
						disabled={submitButtonDisabled}
						color="error"
						variant="gradient"
						size="large"
						onClick={() => navigate(-1)}
					>
						취소하기
					</MDButton>
					<MDButton
						sx={{ mt: 2 }}
						disabled={submitButtonDisabled}
						color="info"
						size="large"
						type="submit"
					>
						등록하기
					</MDButton>
				</MDBox>
			</form>
		</FormProvider>
	)
}

export function Field({ name, title, type, options, Component, ...rest }) {
	const {
		register,
		control,
		formState: { errors },
		setValue,
		getValues,
		watch,
	} = useFormContext()

	switch (type) {
		case "date":
			return (
				<ItemWrapper title={title} md={rest.md} error={errors[name]}>
					<DatePicker key={name} name={name} control={control} {...rest} />
				</ItemWrapper>
			)
		case "date_all":
			return (
				<ItemWrapper title={title} md={rest.md} error={errors[name]}>
					<DatePicker
						key={name}
						name={name}
						disableFuture={false}
						disablePast={true}
						showSundayOnly={false}
						control={control}
						{...rest}
					/>
				</ItemWrapper>
			)
		case "custom":
			return (
				<ItemWrapper title={title} md={rest.md}>
					{Component}
				</ItemWrapper>
			)
		case "toggle": // ✅ Use Material-UI Switch instead of Checkbox
			return (
				<ItemWrapper title={title} md={6}>
					<FormControlLabel
						control={
							<Switch
								{...register(name)}
								color="primary"
								defaultChecked={getValues(name) === "Y"}
								{...rest}
							/>
						}
						label={title} // Label next to the switch
					/>
				</ItemWrapper>
			)

		case "textarea": // ✅ Handle textarea type correctly
			return (
				<ItemWrapper title={title} md={12} error={errors[name]}>
					<TextField
						key={name}
						{...register(name)}
						multiline
						minRows={rest.rows || 5} // Default to 5 rows if not specified
						sx={{ width: "50%" }} // Let it take full width inside its grid
						title={title}
						{...rest}
					/>
				</ItemWrapper>
			)
		case "select": // ✅ Handle dropdown select fields
			return (
				<ItemWrapper title={title} md={6} error={errors[name]}>
					<Select
						fullWidth
						{...register(name)}
						name={name}
						title={title}
						error={!!errors[name]}
						helperText={errors[name]?.message}
						defaultValue={getValues(name) || ""}
						{...rest}
					>
						{options.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</ItemWrapper>
			)
		case "file": // ✅ Handle file upload
			return (
				<ItemWrapper title={title} md={12} error={errors[name]}>
					<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<MDButton variant="contained" component="label">
							<img src={image} alt="Upload Icon" />
							<input
								type="file"
								hidden
								accept="image/*"
								multiple
								{...register(name)}
								onChange={(e) => {
									const files = Array.from(e.target.files) // Convert FileList to Array
									if (files.length) {
										const existingFiles = watch(name) || [] // Get existing files from form state
										setValue(name, [...existingFiles, ...files]) // Append new files to existing files
									}
								}}
								{...rest}
							/>
						</MDButton>
						{watch(name) && watch(name).length > 0 && (
							<div
								style={{
									display: "flex",
									gap: "10px",
									flexWrap: "nowrap",
									maxWidth: "100%",
									overflowX: "scroll",
								}}
							>
								<ScrollableImageList files={watch(name)} setImages={setValue} name={name} />
							</div>
						)}
					</div>
				</ItemWrapper>
			)

		default:
			return (
				<ItemWrapper title={title} md={rest.md} error={errors[name]}>
					<TextField
						key={name}
						{...register(name)}
						type={type}
						name={name}
						onWheel={(e) => e.target.blur()}
						fullWidth
						title={title}
						{...rest}
					/>
				</ItemWrapper>
			)
	}
}

export function ItemWrapper({ children, title, xs, md, error = {} }) {
	return (
		<Grid item xs={xs} md={md} container direction="column" justifyContent="flex-start">
			{title && <FormLabel>{title}</FormLabel>}

			{children}

			<ErrorMessage message={error?.message} />
		</Grid>
	)
}

Form.propTypes = {
	formFields: PropTypes.array.isRequired,
	initialValues: PropTypes.object,
	validationSchema: PropTypes.object,
	onSubmit: PropTypes.func.isRequired,
	submitButtonDisabled: PropTypes.bool,
}

Field.propTypes = {
	name: PropTypes.string.isRequired,
	title: PropTypes.string,
	type: PropTypes.string,
	options: PropTypes.array,
	Component: PropTypes.node,
}

ItemWrapper.defaultProps = {
	xs: 12,
	md: 6,
}

ItemWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	xs: PropTypes.oneOf([6, 12]),
	md: PropTypes.oneOf([6, 12]),
	error: PropTypes.object,
}

import PropTypes from "prop-types"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { homeApi } from "services/modules"
import { openSnackbar } from "stores/slice/snackbar"

import ImageWithControls from "./ImageWithControls"

// Import the new component

const ScrollableImageList = ({ files, setImages, name }) => {
	const dispatch = useDispatch()
	// Function to handle deleting an image
	const handleDelete = (indexToDelete) => {
		const newImages = files.filter((_, index) => index !== indexToDelete)
		dispatch(
			openSnackbar({
				severity: "success",
				message: "이미지가 성공적으로 삭제되었습니다.",
			})
		)
		setImages(name, newImages)
	}

	// Function to handle moving an image to the left
	const handleMoveLeft = (index) => {
		if (index > 0) {
			const newImages = [...files]
			;[newImages[index - 1], newImages[index]] = [newImages[index], newImages[index - 1]]
			setImages(name, newImages)
			dispatch(
				openSnackbar({
					severity: "success",
					message: "이미지 순서가 바뀌었습니다",
				})
			)
		}
	}

	// Function to handle moving an image to the right
	const handleMoveRight = (index) => {
		if (index < files.length - 1) {
			const newImages = [...files]
			;[newImages[index + 1], newImages[index]] = [newImages[index], newImages[index + 1]]
			setImages(name, newImages)
			dispatch(
				openSnackbar({
					severity: "success",
					message: "이미지 순서가 바뀌었습니다",
				})
			)
		}
	}

	return files.map((file, index) => (
		<ImageWithControls
			key={index}
			file={file}
			index={index}
			onDelete={handleDelete}
			onMoveLeft={handleMoveLeft}
			onMoveRight={handleMoveRight}
			isLast={index === files.length - 1}
		/>
	))
}

// Define PropTypes
ScrollableImageList.propTypes = {
	files: PropTypes.arrayOf(PropTypes.object).isRequired,
	setImages: PropTypes.func.isRequired,
	name: PropTypes.string,
}

export default ScrollableImageList

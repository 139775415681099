import { DATE_FORMAT } from "constant"
import { format } from "date-fns"
import { api } from "services/api"
import { API_NEWS, API_NEWS_LIST, API_NEWS_PIN } from "services/endpoints"
import { TAG_NEWS_DETAIL, TAG_NEWS_LIST } from "services/tags"

export default api("newsApi").injectEndpoints({
	endpoints: (build) => ({
		getNewsList: build.query({
			query() {
				return {
					url: API_NEWS_LIST,
				}
			},
			providesTags: [TAG_NEWS_LIST],
		}),
		getNews: build.query({
			query(data) {
				return {
					url: API_NEWS,
					params: {
						news_id: data.news_id,
					},
				}
			},
			transformResponse(response) {
				const data = response?.data || {}
				const { due_date, ...rest } = data

				response.data = {
					...rest,
					due_date: due_date ? new Date(due_date) : "",
				}
				return response
			},
			providesTags: [TAG_NEWS_DETAIL],
		}),
		postNews: build.mutation({
			query({ data, files }) {
				if (data.due_date) {
					data.due_date = format(data.due_date, DATE_FORMAT)
				}

				let formData = new FormData()

				Object.entries(data).forEach(([key, value]) => {
					if (key === "pin_yn") {
						value = value ? "Y" : "N"
					}
					if (key === "files") {
						for (let i = 0; i < value.length; i++) {
							formData.append("files", value[i])
						}
					} else {
						formData.append(key, value)
					}
				})

				return {
					url: API_NEWS,
					method: "POST",
					data: formData,
					headers: {
						"Content-Type": "multipart/form-data;",
					},
					formData: true,
				}
			},
			invalidatesTags: [TAG_NEWS_LIST],
		}),
		putNews: build.mutation({
			query({ data, files }) {
				if (data.due_date) {
					data.due_date = format(data.due_date, DATE_FORMAT)
				}
				let formData = new FormData()

				Object.entries(data).forEach(([key, value]) => {
					if (key === "pin_yn") {
						value = value === "true" ? "Y" : "N"
					}
					if (key === "files") {
						for (let i = 0; i < value.length; i++) {
							formData.append("files", value[i])
						}
					} else if (key === "images") {
						value = JSON.stringify(value)
						formData.append(key, value)
					} else {
						formData.append(key, value)
					}
				})

				return {
					url: API_NEWS,
					method: "PUT",
					data: formData,
					headers: {
						"Content-Type": "multipart/form-data;",
					},
					formData: true,
				}
			},
			invalidatesTags: [TAG_NEWS_LIST, TAG_NEWS_DETAIL],
		}),
		updatePinNews: build.mutation({
			query(data) {
				return {
					url: API_NEWS_PIN,
					data: data,
					method: "PUT",
				}
			},
			invalidatesTags: [TAG_NEWS_LIST, TAG_NEWS_DETAIL],
		}),
		deleteNews: build.mutation({
			query(data) {
				return {
					url: API_NEWS,
					data: data,
					method: "DELETE",
				}
			},
			invalidatesTags: [TAG_NEWS_LIST],
		}),
	}),
})

export const TAG_PARISHES = "parishes"
export const TAG_ATTENDANCE_LIST = "attendanceList"

export const TAG_PRAYERS = "prayers"
export const TAG_USER = "user"

export const TAG_SERMON_LIST = "sermonList"
export const TAG_SERMON_DETAIL = "sermonDetail"

export const TAG_NEWS_LIST = "newsList"
export const TAG_NEWS_DETAIL = "newsDetail"

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import PushPinIcon from "@mui/icons-material/PushPin"
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined"
import SimpleDialog from "components/Dialog"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "examples/Navbars/DashboardNavbar"
import DataTable from "examples/Tables/DataTable"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { newsApi } from "services/modules"
import { openSnackbar } from "stores/slice/snackbar"
import { ROUTE_NEWS, ROUTE_NEWS_MODIFY, ROUTE_NEWS_WRITE } from "urls"

const NewsScreen = () => {
	const [open, setOpen] = useState(false)
	const [rows, setRows] = useState([])
	const [selectedRow, setSelectedRow] = useState(null)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const columns = useMemo(() => {
		return [
			{
				Header: "중요",
				accessor: "pin",
				width: "10%",
				align: "left",
			},
			{
				Header: "제목",
				accessor: "title",
				width: "*",
				align: "left",
			},
			{
				Header: "공지일",
				accessor: "crea_date",
				width: "15%",
				align: "center",
			},
			{
				Header: "공지 보기권한",
				accessor: "role",
				sortable: true,
				align: "center",
				width: "15%",
			},
			{
				Header: "마감일",
				accessor: "due_date",
				sortable: true,
				align: "center",
				width: "15%",
			},
			{
				Header: "삭제",
				accessor: "actions",
				right: false,
				width: "9%",
			},
		]
	}, [])

	const { data = {}, isSuccess, refetch } = newsApi.useGetNewsListQuery()
	const [pinNewsTrigger] = newsApi.useUpdatePinNewsMutation()
	const [deleteNewsTrigger] = newsApi.useDeleteNewsMutation()

	const pinNewsHandler = async (row) => {
		const { news_id, pin_yn } = row
		const newPinYn = pin_yn === "Y" ? "N" : "Y"
		try {
			await pinNewsTrigger({ news_id: news_id, pin_yn: newPinYn }).unwrap()
		} catch (error) {
			console.error(error)
		}
	}

	const deleteNewsHandler = async () => {
		const { news_id } = selectedRow
		try {
			await deleteNewsTrigger({ news_ids: [news_id] }).unwrap()
		} catch (error) {
			console.error(error)
		} finally {
			setOpen(false)
		}
	}

	useEffect(() => {
		if (isSuccess) {
			const newsList = data.data.news_list

			if (!Array.isArray(newsList)) {
				console.error("Unexpected API response format:", data)
				setRows([]) // Set empty array to avoid further errors
				return
			}

			let mappedData = newsList.map((row) => {
				let role
				switch (row.category) {
					case "02":
						role = "교구장 이상"
						break
					case "03":
						role = "속장 이상"
						break
					case "04":
						role = "전체"
						break
					default:
						role = "Unknown"
				}

				return {
					pin:
						row.pin_yn === "Y" ? (
							<PushPinIcon
								color="success"
								fontSize="small"
								onClick={async (e) => {
									e.stopPropagation()
									e.preventDefault()
									await pinNewsHandler(row)
								}}
								sx={{
									cursor: "pointer",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								Pinned
							</PushPinIcon>
						) : (
							<PushPinOutlinedIcon
								color="disabled"
								fontSize="small"
								onClick={async (e) => {
									e.stopPropagation()
									e.preventDefault()
									await pinNewsHandler(row)
								}}
								sx={{
									cursor: "pointer",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								Pinned
							</PushPinOutlinedIcon>
						),
					role,
					...row,
					actions: (
						<DeleteOutlineIcon
							color="error"
							fontSize="small"
							onClick={(e) => {
								e.stopPropagation()
								e.preventDefault()
								setOpen(true)
								setSelectedRow(row)
							}}
							sx={{
								cursor: "pointer",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							Delete
						</DeleteOutlineIcon>
					),
				}
			})
			setRows(mappedData)
		}
	}, [isSuccess, data])

	const onRowClick = useCallback(
		({ original }) => {
			if (!original) return

			navigate(`${ROUTE_NEWS}/${original.news_id}`)
		},
		[navigate]
	)

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox pb={3}>
				<MDBox position="relative">
					<DataTable
						table={{ columns, rows }}
						isSorted={false}
						entriesPerPage={false}
						showTotalEntries={false}
						onRowClick={onRowClick}
					/>
				</MDBox>

				<MDBox display="flex" justifyContent="flex-end" mt={3}>
					<MDButton color="primary" onClick={() => navigate(ROUTE_NEWS_WRITE)}>
						새 공지사항 작성하기
					</MDButton>
				</MDBox>
			</MDBox>
			<SimpleDialog
				open={open}
				onClose={() => setOpen(false)}
				onSuccess={() => {
					deleteNewsHandler()
				}}
				message={`선택된 공지를 삭제하시겠습니까?`}
			/>
		</DashboardLayout>
	)
}

export default NewsScreen

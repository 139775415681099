import Form from "components/Form"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "examples/Navbars/DashboardNavbar"
import PropTypes from "prop-types"
import { useMemo } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { openSnackbar } from "stores/slice/snackbar"
import { ROUTE_NEWS } from "urls"
import { array, object, string } from "yup"

const newsWriteSchema = object({
	news_id: string().nullable(),
	title: string().required("제목을 입력해주세요."),
	due_date: string(),
	category: string().required("카테고리를 선택해주세요."),
	content: string().required("내용을 입력해주세요."),
	pin_yn: string().nullable(),
}).required()

const fields = [
	{
		label: "공지사항",
		fields: [
			{
				title: "제목",
				name: "title",
			},
			{
				title: "마감일",
				name: "due_date",
				type: "date_all",
			},
			{
				title: "공지 권한",
				name: "category",
				type: "select",
				options: [
					{ value: "02", label: "교구장 이상" },
					{ value: "03", label: "속장 이상" },
					{ value: "04", label: "전체" },
				],
			},
			{
				title: "중요 공지 여부",
				name: "pin_yn",
				type: "toggle",
			},
			{
				title: "내용",
				name: "content",
				type: "textarea",
				rows: 10,
			},
			{
				name: "files",
				type: "file",
				label: "Upload Image",
				accept: "image/*",
			},
		],
	},
]

export default function NewsForm({ save, initialValues }) {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [saveTrigger, { isLoading }] = save()

	initialValues = useMemo(() => {
		return {
			...initialValues,
			files: initialValues?.images?.map(({ img_url }) => img_url) || [],
		}
	}, [initialValues])

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<Form
				formFields={fields}
				validationSchema={newsWriteSchema}
				onSubmit={async (data) => {
					try {
						data.images = data.images?.filter(({ img_url }) => data.files.includes(img_url)) || []

						const finalData = {
							data: data,
							files: [],
						}
						await saveTrigger(finalData).unwrap()

						dispatch(openSnackbar({ message: "공지사항이 수정되었습니다." }))

						navigate(ROUTE_NEWS)
					} catch (error) {
						dispatch(
							openSnackbar({
								severity: "error",
								message: error?.message || "오류가 발생했습니다.",
							})
						)
					}
				}}
				submitButtonDisabled={isLoading}
				initialValues={initialValues}
			/>
		</DashboardLayout>
	)
}

NewsForm.propTypes = {
	create: PropTypes.bool,
	initialValues: PropTypes.object,
	isEditing: PropTypes.bool,
	save: PropTypes.func,
}

import { Icon } from "@mui/material"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { FaArrowLeft, FaArrowRight, FaExpand, FaTrashAlt } from "react-icons/fa"

const ImageWithControls = ({ file, index, onDelete, onMoveLeft, onMoveRight, isLast }) => {
	const [hovered, setHovered] = useState(false)
	const [isExpanded, setIsExpanded] = useState(false)

	file = typeof file === "string" ? file : URL.createObjectURL(file)

	return (
		<div>
			<div
				style={{
					position: "relative",
					width: "150px",
					borderRadius: "15px",
					overflow: "hidden",
				}}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
			>
				{/* Image with hover effect */}
				<img
					src={file}
					alt={`Image ${index}`}
					style={{
						width: "150px",
						height: "auto",
						objectFit: "cover",
						borderRadius: "5px",
					}}
				/>

				{/* Controls Container */}
				{hovered && (
					<div
						style={{
							position: "absolute",
							top: "30px",
							left: "50%",
							transform: "translate(-50%, -50%)",
							display: "flex",
							gap: "10px",
							alignItems: "center",
							background: "rgba(0, 0, 0, 0.5)",
							padding: "10px",
							borderRadius: "10px",
						}}
					>
						{/* Left Arrow Button */}
						{/* {index > 0 && (
							<FaArrowLeft
								onClick={() => onMoveLeft(index)}
								style={{ cursor: "pointer", color: "white" }}
							/>
						)} */}

						{/* Expand Button */}
						<FaExpand
							onClick={() => setIsExpanded(true)}
							style={{ cursor: "pointer", color: "white" }}
						/>

						{/* Trash Can Button */}
						<FaTrashAlt
							onClick={() => onDelete(index)}
							style={{ cursor: "pointer", color: "white" }}
						/>

						{/* Right Arrow Button */}
						{/* {!isLast && (
							<FaArrowRight
								onClick={() => onMoveRight(index)}
								style={{ cursor: "pointer", color: "white" }}
							/>
						)} */}
					</div>
				)}
			</div>

			{/* Popup Window for Expanded Image */}
			{isExpanded && (
				<div
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						width: "100vw",
						height: "100vh",
						background: "rgba(0, 0, 0, 0.8)",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						zIndex: 1000,
						backdropFilter: "blur(10px)",
					}}
					onClick={() => setIsExpanded(false)}
				>
					<img
						src={file}
						alt={`Expanded Image ${index}`}
						style={{
							maxWidth: "90%",
							maxHeight: "90%",
							borderRadius: "10px",
						}}
					/>
				</div>
			)}
		</div>
	)
}

// Define PropTypes
ImageWithControls.propTypes = {
	file: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	onDelete: PropTypes.func.isRequired,
	onMoveLeft: PropTypes.func.isRequired,
	onMoveRight: PropTypes.func.isRequired,
	isLast: PropTypes.bool.isRequired,
}

export default ImageWithControls

const ADMIN_API = "/admin"
export const API_ADMIN_ATTENDANCE = `${ADMIN_API}/attendance` // 출석관리
export const API_ADMIN_HIERARCHY = `${ADMIN_API}/hierarchy` // 교구/속회 관계도
export const API_ADMIN_UPDATE_USER = `${ADMIN_API}/update_user` //프로필 수정
export const API_ADMIN_PRAYERS = `${ADMIN_API}/prayers` // 기도제목 가져오기
export const API_ADMIN_USER = `${ADMIN_API}/user` // 유저 정보 가져오기
export const API_ATTENDANCE_UNCHECKED = `${ADMIN_API}/attendance_unchecked` // 출석체크 안 한 속회 가져오기

const PROGRAM_API = "/home/program"
export const API_PROGRAM_UPLOAD_IMAGES = `${PROGRAM_API}/upload_images`
export const API_PROGRAM_GET_IMAGES = `${PROGRAM_API}/get_images`
export const API_PROGRAM_CHANGE_ORDER = `${PROGRAM_API}/change_order`
export const API_PROGRAM_DELETE_IMAGE = `${PROGRAM_API}/delete_image`
export const API_SERMON_LIST = `${ADMIN_API}/sermon_list` // 설교 리스트 가져오기
export const API_SERMON_SUMMARY = `${ADMIN_API}/sermon_summary` // 설교 요약 정보 가져오기

const NEWS_API = "/news"
export const API_NEWS_LIST = `${NEWS_API}/all` // 공지사항 리스트 가져오기
export const API_NEWS_PIN = `${NEWS_API}/pin` // 공지사항 핀 설정
export const API_NEWS = `${NEWS_API}/` // 공지사항 가져오기, 수정하기, 삭제하기, 쓰기

import { FetchById } from "common/fetcherHOC"
import { newsApi } from "services/modules"

import NewsForm from "./form"

export const NewsEdit = FetchById(
	function NewsEdit({ response: { data } }) {
		console.log(data)
		return <NewsForm save={newsApi.usePutNewsMutation} initialValues={data} />
	},
	newsApi.useGetNewsQuery,
	["news_id"]
)

export const NewsWrite = () => {
	return (
		<NewsForm
			save={newsApi.usePostNewsMutation}
			initialValues={{
				content: "",
			}}
		/>
	)
}

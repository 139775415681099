import { Card, Grid } from "@mui/material"
import { ItemWrapper } from "components/Form"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "examples/Navbars/DashboardNavbar"
import PropTypes from "prop-types"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { newsApi } from "services/modules"
import { ROUTE_NEWS } from "urls"

const NewsDetail = () => {
	const { id } = useParams()
	const { state } = useLocation()
	const navigate = useNavigate()

	const { data } = newsApi.useGetNewsQuery({ news_id: id })
	const { data: { title, content, pin_yn, category, due_date, crea_date, images } = {} } =
		data || {}

	const updateHandler = () => {
		navigate(`${ROUTE_NEWS}/${id}/modify`, { state: { news_id: id } })
	}

	const fields = [
		{
			label: "공지사항",
			fields: [
				{
					title: "제목",
					name: "title",
				},
				{
					title: "마감일",
					name: "due_date",
					type: "date_all",
				},
				{
					title: "공지 권한",
					name: "category",
					type: "select",
					options: [
						{ value: "02", label: "교구장 이상" },
						{ value: "03", label: "속장 이상" },
						{ value: "04", label: "전체" },
					],
				},
				{
					title: "중요 공지 여부",
					name: "pin_yn",
					type: "toggle",
				},
				{
					title: "내용",
					name: "content",
					type: "textarea",
					rows: 10,
				},
			],
		},
	]

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox p={3}>
				<Card>
					<MDBox
						sx={{
							position: "absolute",
							top: 24, // Adjusts the top position
							right: 16, // Aligns buttons to the right
							display: "flex",
							gap: 2, // Space between buttons
						}}
					>
						<MDButton variant="gradient" color="primary" size="medium" onClick={updateHandler}>
							수정하기
						</MDButton>
						<MDButton
							variant="gradient"
							color="secondary"
							size="medium"
							onClick={() => navigate(-1)}
						>
							목록으로
						</MDButton>
					</MDBox>
					<MDBox p={4}>
						<MDTypography variant="h5">공지사항 내용</MDTypography>

						<Grid container spacing={2} pt={4}>
							<FieldWrapper title="공지 제목" value={title} md={2} />
							<FieldWrapper
								title="마감일"
								value={due_date ? new Date(due_date).toISOString().split("T")[0] : ""}
								md={2}
							/>
							<FieldWrapper
								title="보기 권한"
								value={
									{
										"02": "교구장 이상",
										"03": "속장 이상",
										"04": "전체",
									}[category]
								}
								md={2}
							/>
							<FieldWrapper title="중요 공지" value={pin_yn === "Y" ? "중요" : "일반"} md={2} />
							<FieldWrapper title="등록일" value={crea_date} md={2} />
						</Grid>
					</MDBox>
					<MDBox p={4} pt={0}>
						<MDTypography variant="h4">내용</MDTypography>
						<MDBox
							sx={{
								backgroundColor: "#f5f5f5", // ✅ Light gray background
								borderRadius: "8px", // ✅ Rounded corners for a cleaner look
								padding: "16px", // ✅ Adds spacing inside the box
							}}
						>
							<MDTypography
								variant="body2"
								gutterBottom
								sx={{
									whiteSpace: "pre-wrap", // ✅ Preserves line breaks and spaces
									width: "100%", // ✅ Ensures full width
									wordBreak: "break-word", // ✅ Prevents overflow for long words
								}}
							>
								{content}
							</MDTypography>
						</MDBox>
					</MDBox>
					{images?.length > 0 && (
						<MDBox p={4} pt={0}>
							<MDTypography variant="h4">첨부 파일</MDTypography>
							<MDBox display="flex" flexWrap="wrap" gap={2}>
								{images.map(({ img_id, img_url }) => (
									<img
										key={img_id}
										src={img_url}
										alt="첨부 이미지"
										style={{ width: 200, height: 200, objectFit: "contain", borderRadius: 8 }}
									/>
								))}
							</MDBox>
						</MDBox>
					)}
				</Card>
			</MDBox>
		</DashboardLayout>
	)
}

const FieldWrapper = ({ children, title, value, ...rest }) => (
	<ItemWrapper title={title} {...rest}>
		{children ? (
			children
		) : (
			<MDTypography variant="h6" gutterBottom>
				{value}
			</MDTypography>
		)}
	</ItemWrapper>
)

FieldWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
}

export default NewsDetail
